import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './national_font.css';
import './custom.css';


export default class App extends Component {
  static displayName = App.name;
  state = { isSatelliteLoaded: false };

    componentDidMount() {
        const scriptUrl = process.env.REACT_APP_ADOBE_TRACKING_URL;
       
        //if (scriptUrl) {
        //    const scriptPlaceholder = document.getElementById('adobe-tracking-script');
        //    if (scriptPlaceholder) {
        //        const script = document.createElement('script');
        //        script.src = scriptUrl;
        //        script.async = true;
        //        scriptPlaceholder.replaceWith(script); // Replace placeholder with actual script
        //    }

        //}


        function loadScript(url) {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = url;
                script.async = true;
                script.onload = () => resolve();
                script.onerror = () => reject(new Error(`Script load error for ${url}`));
                document.head.appendChild(script);
            });
        }

        if (scriptUrl) {
            loadScript(scriptUrl)
                .then(() => {
                    // Tracking script loaded successfully, now run the digitalData script
                    const digitalDataScript = document.createElement('script');
                    digitalDataScript.innerHTML = `
                        var digitalData = (typeof digitalData !== 'undefined') ? digitalData : {};
                        //Page level analytics.
                        digitalData.page = {
                          "pageInfo": {
                            "pageName": "USNDP Grant application",
                            "tags": []
                          },
                          "category": {
                            "primaryCategory": "USNDP",
                            "secondaryCategory": "",
                            "pageType": ""
                          }
                        };
                        _satellite.track("page-view");
     
                      `;
                    document.head.appendChild(digitalDataScript);

                    const pageBottomScript = document.createElement('script');
                    pageBottomScript.type = 'text/javascript';
                    pageBottomScript.innerHTML = `_satellite.pageBottom();`;
                    document.body.appendChild(pageBottomScript);

                    this.setState({ isSatelliteLoaded: true }); // Indicate that the script has been loaded

                })
                .catch((error) => {
                    console.error(error);
                });
        }


    }

  render() {
    return (
      <Layout>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
              return <Route key={index} {...rest} element={React.cloneElement(element, { isSatelliteLoaded: this.state.isSatelliteLoaded })} />;
          })}
        </Routes>
      </Layout>
    );
  }
}
