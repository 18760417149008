import React, { Component } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import SelectFormGroup from './Shared/SelectFormGroup';
import TextboxFormGroup from './Shared/TextboxFormGroup';
import img2 from '../../src/assets/img/usndp2.jpg';
import img3 from '../../src/assets/img/usndp3.jpg';
import img4 from '../../src/assets/img/usndp4.jpg';
import img5 from '../../src/assets/img/usndp5.jpg';
import usndpImg from '../../src/assets/img/USA_Color_Logo_fullcolor.webp'
import { Formik, Form, Field, ErrorMessage } from 'formik';


const images = [
    img2,
    img3,
    img4,
    img5
];
// Validation function for Formik
const validate = values => {
    const errors = {};
    const hiCheckValue = Number(values.hiCheck);
    if (isNaN(hiCheckValue) || hiCheckValue > 6) {
        errors.hiCheck = 'Please choose an HI range.';
    }
    if (typeof values.ageCheck !== 'boolean' && values.ageCheck !== "false" && values.ageCheck !== "true") errors.ageCheck = 'Age Check must be either yes or no.';
    if (typeof values.citizenCheck !== 'boolean' && values.citizenCheck !== "false" && values.citizenCheck !== "true") errors.citizenCheck = 'Citizen Check must be either yes or no.';
    if (typeof values.amateurCheck !== 'boolean' && values.amateurCheck !== "false" && values.amateurCheck !== "true") errors.amateurCheck = 'Amateur Check must be either yes or no.';
    if (isNaN(values.householdCheck)) errors.householdCheck = 'Please choose the number of people in the household.';
    if (!values.incomeCheck || isNaN(values.incomeCheck) || values.incomeCheck < 0) errors.incomeCheck = 'Income must be a positive number.';
    // Add other validation rules here

    return errors;
};
// Validation function for Formik
const validateEmail = values => {
    const errors = {};
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!values.txtEmail || !emailRegex.test(values.txtEmail)) {
        errors.txtEmail = 'Invalid email address';
    }

    if (!values.txtEmailConfirm || !emailRegex.test(values.txtEmailConfirm)) {
        errors.txtEmailConfirm = 'Invalid email address';
    }

    if (values.txtEmail !== values.txtEmailConfirm) {
        errors.txtEmailConfirm = 'Email addresses do not match';
    }

    return errors;
};
const baseUrl = `${process.env.REACT_APP_BASEURL}/api/grant/`;
//const baseUrl = `${process.env.REACT_APP_BASEURL || 'https://usndp-app-granteligibility-dev.azurewebsites.net'}/api/grant/`;
export class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showEligible: false,
            showIneligible: false,
            showEmailSuccess: false,
            showEmailFail: false,
            hideForm: false,
            showCalc: true,
            stockImg: images[Math.floor(Math.random() * images.length)],
            q1year: "2025"
        };
    }



    submitEmail = async (values) => {
        const response = await fetch(`${baseUrl}submitemail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: values.txtEmailConfirm
            })
        });

        const data = await response.json();

         if (data.Created === true) {
           //show thanks for submitting page
            this.setState({ showEligible: false, hideForm: true, showEmailFail: false, showEmailSuccess: true });

        } else {
           //show something went wrong page
            this.setState({ showEligible: false, hideForm: true, showEmailFail: true, showEmailSuccess: false });
         }
    }

    checkEligibility = async (values) => {

        // Extend the digitalData object with new properties
        const setDtm = (props) => {
            window.digitalData = window.digitalData || {};
            window.digitalData = { ...window.digitalData, ...props };
        };

        // Track DTM with a callback option
        const trackDtm = (event, props, onDtmTrack) => {
            if (window._satellite) {
                setDtm(props);
                window._satellite.track(event);
                if (typeof onDtmTrack === 'function') {
                    setTimeout(() => {
                        onDtmTrack(event, props, window.digitalData);
                    }, 1000);
                }
            }
        };


        const response = await fetch(`${baseUrl}calceligibility`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ageCheck: JSON.parse(values.ageCheck.toLowerCase()),
                citizenCheck: JSON.parse(values.citizenCheck.toLowerCase()),
                amateurCheck: JSON.parse(values.amateurCheck.toLowerCase()),
                hiCheck: values.hiCheck,
                income: values.incomeCheck,
                householdNum: values.householdCheck
            })
        });

        const data = await response.json();

        if (data.Eligible === 'True') {
            this.setState({ showEligible: true, hideForm: true, showEmailFail: false, showEmailSuccess: false });
            trackDtm('usndp-submit-cta-click', { usndpcta: 'USNDP Submit CTA Click' })
            //console.log("eligible click");
        } else {            
            this.setState({ showIneligible: true, hideForm: true, showEmailFail: false, showEmailSuccess: false });
            trackDtm('usndp-submit-cta-click', { usndpcta: 'USNDP Submit CTA Click' })
            //console.log("not eligible click");
        }
    };

    render() {
        return (
            <>
                <Formik initialValues={{
                    incomeCheck: '',
                    ageCheck: "none",
                    citizenCheck: "none",
                    amateurCheck: "none",
                    hiCheck: "999",
                    householdCheck: "none"
                }}
                    validate={validate}
                    onSubmit={this.handleSubmit}>

                    {({ submitForm, validateForm, errors, setTouched, values, setErrors, setFieldError }) => (
                        <Form onSubmit={this.handleSubmit}>
                            {/*<Row>*/}
                            {/*    <Col>*/}
                            {/*        <div className="USA-logo">*/}
                            {/*            <img className="usndpLogo" src={usndpImg} alt="USNDP logo"></img>*/}
                            {/*        </div>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <Row className="row hero-banner-component_container_headerCard">
                                <Col md={4} lg={5} xl={5} xs={12} className="hero-banner-component_container-size hero-banner-component_text ">
                                    <div className="card-body hero-banner-component_container_headerCard_body ">
                                        <h1 className="">Pre-Screening Survey</h1>
                                        <p className="regular-s hero-banner-component_container_headerCard_body_description">
                                            The U.S. National Development Program (USNDP) Grant application is <strong>NOW OPEN</strong>!
                                        </p>
                                        <p className="regular-s hero-banner-component_container_headerCard_body_description">
                                            The USNDP Grant is designed to mitigate barriers of cost to promising junior golfers
                                            who demonstrate both the financial need and the potential to progress through the USNDP pathway.
                                        </p>
                                        <p className="regular-s hero-banner-component_container_headerCard_body_description">
                                            Grants up to $10,000 will be awarded and will reimburse costs associated with practice/green
                                            fees, competitive tournament fees, tournament travel, instructional fees, and equipment up
                                            to a limit that the USGA deems as appropriate for each area.
                                        </p>
                                        <p className="regular-s hero-banner-component_container_headerCard_body_description">
                                            Families who are new to applying and are interested in pursuing funding must complete
                                            the application pre-screening survey below to see if they meet the criteria to apply for
                                            the USNDP Grant. Families that meet the criteria to be considered for a USNDP Grant will
                                            receive an email with a link to the application.
                                        </p>
                                        <p className="regular-s hero-banner-component_container_headerCard_body_description">
                                            <a href='https://www.usga.org/content/usga/home-page/articles/2024/06/2025-u-s--national-development-program-grant-application-overvie.html'><strong>APPLICATION OVERVIEW</strong></a>
                                            <br/>
                                            The application closes October 31, 2024.
                                            Award announcements will be made via email the week of January 20, 2025.
                                            Grants will be awarded for a 12-month period (Jan.&nbsp;1 - Dec.&nbsp;31,&nbsp;2025). 
                                            The total number of grants awarded will be determined by the total number of qualified athletes and availability of funds.   
                                        </p>
                                        
                          
                                    </div>

                                </Col>
                                <Col md={7} lg={7} xs={12} xl={7} className="hero-banner-component_container-size">
                                    <img className="img-responsive" src={this.state.stockImg} alt="logo" />
                                </Col>

                            </Row>
                            <Row className="blueSpacer d-flex align-items-center mx-0 mb-3">
                                <Col md={{ size: 12, offset: 0 }} className="ps-0">
                                    <h3 className="align-self-center">Please Fill Out the Required Fields Below</h3>
                                </Col>
                            </Row>

                            <div className={this.state.hideForm ? 'd-none' : ''}>

                                <Row className="mb-3 px-2">
                                    <Col md={{ size: 8, offset: 2 }}>
                                        <p className="regular-s hero-banner-component_container_headerCard_body_description">
                                            <strong>Please note: Grant eligibility is for athletes looking to participate in the U.S. National
                                            Development Program. The results displayed to you are not official offers and only provide
                                            information based upon possible eligibility. To receive an official grant award, you must
                                                complete the application process on the secure application portal by the applicable deadline.</strong>
                                        </p>
                                    </Col>
                                </Row>
                                <Field
                                    as={SelectFormGroup}
                                    label={`Is the athlete 13-18 years old with a projected high school graduation date (or GED) no earlier than ${this.state.q1year}?`}
                                    id="ageCheck"
                                    name="ageCheck"
                                    required={true}
                                    options={[
                                        { value: "none", label: "" },
                                        { value: "true", label: "Yes" },
                                        { value: "false", label: "No" }
                                    ]}
                                />
                                <Field
                                    as={SelectFormGroup}
                                    label="Is the athlete a citizen of the United States of America?"
                                    id="citizenCheck"
                                    name="citizenCheck"
                                    required={true}
                                    options={[
                                        { value: "none", label: "" },
                                        { value: "true", label: "Yes" },
                                        { value: "false", label: "No" }
                                    ]}
                                />
                                <Field
                                    as={SelectFormGroup}
                                    label="Is the athlete an amateur player as defined under the <a href='https://www.usga.org/content/usga/home-page/rules-hub/amateur-status/amateur-status-modernization/table-of-contents.html'>Rules of Amateur Status</a>?"
                                    id="amateurCheck"
                                    name="amateurCheck"
                                    required={true}
                                    options={[
                                        { value: "none", label: "" },
                                        { value: "true", label: "Yes" },
                                        { value: "false", label: "No" }
                                    ]}
                                />
                                <Field
                                    as={SelectFormGroup}
                                    label="What is the athlete's <a href='https://www.usga.org/content/usga/home-page/us-national-development-program/u-s--national-development-program-usndp-handicap-index--overview.html'>Handicap Index<sup>&reg;</sup></a>?"
                                    id="hiCheck"
                                    name="hiCheck"
                                    required={true}
                                    options={[
                                        { value: "999", label: "" },
                                        { value: "0", label: "+5.5 or better" },
                                        { value: "1", label: "+5.4 to +2.5" },
                                        { value: "2", label: "+2.4 to 2.4" },
                                        { value: "3", label: "2.5 to 5.4" },
                                        { value: "4", label: "5.5 to 7.4" },
                                        { value: "5", label: "7.5 to 9.9" },
                                        { value: "6", label: "10+" }
                                    ]}

                                />
                                <Field
                                    as={TextboxFormGroup}
                                    label="Total Household Income"
                                    id="incomeCheck"
                                    name="incomeCheck"
                                    required={true}
                                    inputType="number"
                                    infoText="Total Household Income includes both taxable and non-taxable income. The most common taxable examples include but are not limited to salary/wages, interest/dividend income, capital gain, etc. The most common non-taxable examples include but are not limited to child support received and social security benefits."
                                />
                                <Field
                                    as={SelectFormGroup}
                                    label="Number of people in household?"
                                    id="householdCheck"
                                    name="householdCheck"
                                    required={true}
                                    options={[
                                        { value: "none", label: "" },
                                        { value: "1", label: "1" },
                                        { value: "2", label: "2" },
                                        { value: "3", label: "3" },
                                        { value: "4", label: "4" },
                                        { value: "5", label: "5" },
                                        { value: "6", label: "6" },
                                        { value: "7", label: "7" },
                                        { value: "8", label: "8" },
                                        { value: "9", label: "9+" }
                                    ]}
                                />

                                <Row className="mb-5 px-2">
                                    <Col md={{ size: 8, offset: 2 }} className="text-center">
                                        <Button className="formButton" color="primary"
                                            onClick={async () => {
                                                const errors = await validateForm();
                                                setTouched({
                                                    ageCheck: true,
                                                    citizenCheck: true,
                                                    amateurCheck: true,
                                                    hiCheck: true,
                                                    householdCheck: true,
                                                    incomeCheck: true
                                                });
                                                if (Object.keys(errors).length === 0) {
                                                    // If no errors, proceed with checkEligibility
                                                    this.checkEligibility(values);
                                                } else {
                                                    // Handle errors (e.g., show an alert or message)
                                                }
                                            }}>Submit</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    )}
                </Formik>



                <Formik initialValues={{

                    txtEmail: '',
                    txtEmailConfirm: ''
                }}
                    validate={validateEmail}
                    onSubmit={this.handleSubmit}>

                    {({ submitForm, validateForm, errors, setTouched, values, setErrors, setFieldError }) => (
                        <Form onSubmit={this.handleSubmit}>

                            <div id="divCalc">


                                <div>







                                    {
                                        this.state.showEligible ?
                                            <div>
                                                <Row className="mb-5 px-2">
                                                    <Col md={{ size: 8, offset: 2 }}>
                                                    <p>
                                                            Thank you for your interest in the U.S. National Development Program Grant.
                                                            Based on the information that was provided, we recommend that you apply for a USNDP Grant.
                                                    </p>
                                                    <p>
                                                        This is only a recommendation and does not guarantee your USNDP Grant application will be selected for an award.
                                                    </p>
                                                    <p>
                                                            To receive a link to the application, please provide your email address below and
                                                            a link to the application will be emailed to you. Please be sure to check your Inbox in
                                                            addition to your Spam Folder for an email from "U.S. National Development Program Grant".
                                                    </p>
                                                    <p>
                                                        If you have any questions, please contact <a href="USNDPgrants@usga.org">USNDPgrants@usga.org</a>.
                                                    </p>

                                                    </Col>
                                                </Row>

                                                <Field
                                                    as={TextboxFormGroup}
                                                    label="Enter Email Address"
                                                    id="txtEmail"
                                                    name="txtEmail"
                                                    required={true}
                                                    inputType="text"
                                                />
                                                <Field
                                                    as={TextboxFormGroup}
                                                    label="Confirm Email Address"
                                                    id="txtEmailConfirm"
                                                    name="txtEmailConfirm"
                                                    required={true}
                                                    inputType="text"
                                                />

                                                <Row className="mb-5 px-2">
                                                    <Col md={{ size: 8, offset: 2 }} className="text-center">
                                                        <Button className="formButton" color="primary" onClick={async () => {
                                                            const emailErrors = await validateForm();
                                                            setTouched({
                                                                txtEmail: true,
                                                                txtEmailConfirm: true
                                                            });

                                                            if (Object.keys(emailErrors).length === 0) {
                                                                // If no errors, proceed with checkEligibility
                                                                this.submitEmail(values);
                                                            } else {
                                                                // Handle errors (e.g., show an alert or message)
                                                            }
                                                        }}>
                                                            Submit
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={{ size: 8, offset: 2 }}>
                                                        <p className="text-danger">
                                                            Please note: Grant eligibility is for athletes looking to participate in the U.S. National Development Program. The results displayed to you are not official offers and only provide information based upon possible eligibility. To be considered for an official grant award, you must complete the application process by the applicable deadline.
                                                            The USNDP Grant Committee will review all grant applications, and grants will be awarded based on the committee's decision.
                                                        </p>
                                                        <p>
                                                            By providing the information above and clicking "Submit", you acknowledge and agree that the information collected will be used in accordance the <a href='https://www.usga.org/content/usga/home-page/usga-privacy-policy.html.html'>USGA Privacy Policy</a>.
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                            : null
                                    }
                                    {
                                        this.state.showIneligible ?

                                            <Row className="mb-5 px-2" >
                                                <Col md={{ size: 8, offset: 2 }}>
                                                    <p>Thank you for your interest in the U.S. National Development Program Grant. Based on
                                                        the information you provided, we do not recommend you continue the process of
                                                        applying for a USNDP Grant.
                                                    </p>
                                                    <p>
                                                        Although you do not currently meet the criteria for a grant this year, we encourage you
                                                        to complete a <a href="http://www.usga.org/usndp/golfresume">USNDP Golf Resume</a> to get on our radar and be considered for future USNDP
                                                        opportunities. Identified athletes will have opportunities to be part of the USNDP through
                                                        USNDP-sponsored tournament exemptions, access to resources and virtual sessions with coaches and specialists.
                                                    </p>
                                                    <p>
                                                        If you have any questions, please contact <a href="mailto:USNDPgrants@usga.org">USNDPgrants@usga.org</a>.
                                                    </p>
                                                    <p>
                                                        To stay up-to-date on the program, please visit the <a href="https://www.usga.org/content/usga/home-page/usndp-home-page.html">USNDP website</a>. You can also follow
                                                        us on <a href="https://www.facebook.com/usndpgolf">Facebook</a>, <a href="https://twitter.com/usndpgolf">X (formerly Twitter)</a>, and <a href="https://www.instagram.com/usndpgolf">Instagram</a>.
                                                    </p>
                                                </Col>
                                            </Row>
                                            : null
                                    }

                                </div>


                            </div>
                        </Form>
                    )}
                </Formik>

                {
                    this.state.showEmailSuccess ?
                        <Row className="mb-5 px-2" >
                            <Col md={{ size: 8, offset: 2 }}>
                                <h2>SUBMISSION SUCCESSFUL!</h2>
                                <p>Your inquiry to access the U.S. National Development Program Grant application portal was successfully
                                    submitted.</p>
                                <p>
                                    Please look for an email from "U.S. National Development Grants" in your inbox.
                                </p>
                                <p>
                                    Use Gmail? If our email is in your "Promotions" tab, drag it into your "Primary" folder.
                                </p>
                                <p>
                                    (Note: If you don't receive our email within the next hour, check your spam or junk folder.)
                                </p>
                                <p>
                                    Questions? Email us at <a href="mailto:USNDPgrants@usga.org">USNDPgrants@usga.org</a>.
                                </p>
                            </Col>
                        </Row> : null
                }
                {
                    this.state.showEmailFail ?
                        <Row className="mb-5 px-2" >
                            <Col md={{ size: 8, offset: 2 }}><p>Something went wrong, you may have already applied!</p>
                            </Col>
                        </Row> : null 
                }

            </>
        );
    }
}
