import React, { useState } from 'react';
import { Row, Col, FormGroup, Label, Tooltip } from 'reactstrap';
import ReactHtmlParser from 'html-react-parser';
import { BsInfoCircle } from 'react-icons/bs';
import { ErrorMessage } from 'formik'

const SelectFormGroup = ({ label, id, options, required, infoText, field, ...props }) => {
    // State to manage Tooltip visibility
    const [tooltipOpen, setTooltipOpen] = useState(false);

    // Function to toggle Tooltip visibility
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    return (
        <Row className="mb-4 px-2">
            <Col md={{ size: 8, offset: 2 }}>
                <FormGroup className="input-form-group">
                    <Label for={id}>
                        {ReactHtmlParser(label)}
                        {required && <span style={{ color: 'red' }}> *</span>}
                    </Label>
                    {/* Conditionally render BsInfoCircle and Tooltip if infoText exists */}
                    {infoText && (
                        <>
                            &nbsp;
                            <BsInfoCircle id={`tooltip-${id}`} />
                            <Tooltip
                                placement="top"
                                isOpen={tooltipOpen}
                                target={`tooltip-${id}`}
                                toggle={toggleTooltip}
                            >
                                {infoText}
                            </Tooltip>
                        </>
                    )}
                    <select id={id}{...field}
                        {...props} >
                        {options.map((opt, index) => (
                            <option key={index} value={opt.value}>{opt.label}</option>
                        ))}
                    </select>
                </FormGroup>
                <ErrorMessage name={props.name} render={msg => <div className="error-message">{msg}</div>} />
            </Col>
        </Row>
    );
};

export default SelectFormGroup;