
import React, { useState } from 'react';
import { Row, Col, FormGroup, Label, Tooltip  } from 'reactstrap';
import ReactHtmlParser from 'html-react-parser'; // Import react-html-parser
import { BsInfoCircle } from 'react-icons/bs';
import { ErrorMessage } from 'formik'

const TextboxFormGroup = ({ label, id,  inputType, required, infoText, field, ...props }) => {
    // State to manage Tooltip visibility
    const [tooltipOpen, setTooltipOpen] = useState(false);

    // Function to toggle Tooltip visibility
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    return (
        <Row className="mb-4 px-2">
            <Col md={{ size: 8, offset: 2 }}>
                <FormGroup className="input-form-group">
                    <Label for={id}>
                        {ReactHtmlParser(label)}{/* Use ReactHtmlParser to render HTML */}
                        {required && <span style={{ color: 'red' }}> *</span>}</Label>
                    {infoText && (
                        <>
                            &nbsp;
                            <BsInfoCircle id={`tooltip-${id}`} />
                            <Tooltip 
                                placement="top"
                                isOpen={tooltipOpen}
                                target={`tooltip-${id}`}
                                toggle={toggleTooltip}
                                innerClassName="custom-tooltip"
                            >
                                {infoText}
                            </Tooltip>
                        </>
                    )}
                    <input type={inputType} {...field} id={id} {...props }   />
                </FormGroup>
                <ErrorMessage name={props.name} render={msg => <div className="error-message">{msg}</div>} />
            </Col>
        </Row>
    );
};
export default TextboxFormGroup;